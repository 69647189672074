import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {BehaviorSubject, iif, Observable, of, throwError} from 'rxjs';
import {catchError, filter, map, switchMap, tap} from 'rxjs/operators';
import {AuthUtils} from 'app/core/auth/auth.utils';
import {UserService} from 'app/core/user/user.service';
import {environment} from '../../../environments/environment';
import {SignupPostBodyInterface} from '../../modules/auth/sign-up/services/interface';
import {SharedService} from '../../shared/services/shared.service';
import {SettingsModel} from '../../modules/admin/settings/model/settings.model';
import IChangePasswordRequest = SettingsModel.IChangePasswordRequest;
import {AuthModel} from '../../shared/models/auth.interface';
import {BaseResponse} from '../../shared/models/response.interface';
import {ISignInRequest} from "./auth.model";

@Injectable()
export class AuthService {
    protected _state = new BehaviorSubject(this.authenticated);
    private readonly userRoles: AuthModel.UserRoles[] = ['CLIENT', 'CLIENTS'];

    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
        private _sharedService: SharedService
    ) {
    }

    set accessToken(token: string) {
        this._sharedService.setCookie('accessToken', token, 7, true);
    }

    get accessToken(): string {
        return this._sharedService.getCookie('accessToken') ?? '';
    }

    get authenticated(): boolean {
        return !AuthUtils.isTokenExpired(this.accessToken) || (!!this.accessToken && this.accessToken !== 'undefined');
    }

    get authenticated$(): Observable<boolean> {
        return this._state.asObservable();
    }

    refresh(): void {
        this._state.next(this.authenticated);
    }

    forgetPassword(email: string | any): Observable<any> {
        // @ts-ignore
        return this._httpClient.post(environment.APIUrl + 'auth/forget-password?email=' + email);
    }

    resetPassword(password: string): Observable<any> {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    signIn(credentials: ISignInRequest): Observable<any> {
        if (this.authenticated) {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post<BaseResponse<AuthModel.ILoginResponse>>(environment.APIUrl + 'auth/login', credentials).pipe(
            map(response => response.data),
            switchMap(response => iif(() => !this.userRoles.includes(response.roleGroup),
                throwError('Invalid Credentials'),
                of(response))),
            tap((response: AuthModel.ILoginResponse) => {
                this.accessToken = response?.token;
                this.refresh();
                return of(response);
            }));
    }

    refreshToken(): Observable<any> {
        // Renew token
        const headers = new HttpHeaders().set('Authorization', this.accessToken);
        return this._httpClient.get(environment.APIUrl + 'auth/refresh', {headers: headers}).pipe(
            catchError(() =>

                // Return false
                of(false)
            ),
            switchMap((response: any) => {
                this.accessToken = response?.data;
                this.refresh();
                return of(true);
            })
        );
    }

    signOut(): Observable<any> {
        const token = this._sharedService.getCookie('accessToken')?.split(' ')[1];
        const options = {headers: new HttpHeaders().set('Content-Type', 'application/json')};
        return this._httpClient.post<any>(environment.APIUrl + 'auth/logout', {token}, options);
    }

    signUp(user: SignupPostBodyInterface): Observable<any> {
        return this._httpClient.post(environment.APIUrl + 'auth/signup/client', user);
    }

    signOutIfTokenExist(): Observable<void>  {
        return of(this.accessToken).pipe(
            filter(token => !!token && token !== 'undefined'),
            switchMap(() => this.signOut()));
    }

    refreshIfTokenExist(): Observable<void>  {
        return of(this.accessToken).pipe(
            filter(token => !!token && token !== 'undefined'),
            switchMap(() => this.refreshToken()));
    }


    //Check the authentication status
    check(): Observable<boolean> {

        // Check the access token availability
        if (this.accessToken === 'undefined' || !this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return of(true);
    }

    changePassword(data: IChangePasswordRequest): Observable<any> {
        return this._httpClient.patch(environment.APIUrl + 'user/profile/password', data);
    }

    verifyEmail(token: string): Observable<any> {
        const params = new HttpParams().append('token', token);
        return this._httpClient.get(environment.APIUrl + 'client/clients/verify', {params});
    }
}
