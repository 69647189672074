import {Component, OnInit} from '@angular/core';
import {SharedService} from '../../services/shared.service';
import {Router} from '@angular/router';
import {Category} from '../../../modules/admin/home/services/interfaces';
import {AuthService} from '../../../core/auth/auth.service';
import {MatDialog} from '@angular/material/dialog';
import {BehaviorSubject, Observable} from 'rxjs';
import {UserService} from '../../../core/user/user.service';
import {LogoutDialogComponent} from '../logout-dialog/logout-dialog.component';
import {UserInterface} from '../../models/user.interface';
import {shareReplay, switchMap} from 'rxjs/operators';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    notifications$: Observable<UserInterface.Notifications>;
    categories$: Observable<Category[]>;
    triggerRefresh: BehaviorSubject<number> = new BehaviorSubject<number>(20);

    constructor(public sharedService: SharedService, private router: Router, public authService: AuthService,
                private _dialog: MatDialog, public _userService: UserService) {
    }

    ngOnInit(): void {
        this.notifications$ = this.getNotifications();
        this.categories$ = this.sharedService.categoriesTree;
    }

    getNotifications(): Observable<UserInterface.Notifications> {
        return this.triggerRefresh.pipe(switchMap(size => this._userService.getNotifications({size})), shareReplay(1));
    }

    searchForWorkshop(selectedWorkshopName: string): void {
        this.router.navigateByUrl('/workshops/list?search=' + selectedWorkshopName);
    }

    openLogoutDialog(): void {
        this._dialog.open(LogoutDialogComponent, LogoutDialogComponent.dialogConfig());
    }
}
