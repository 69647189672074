import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-small-menu',
  templateUrl: './small-menu.component.html',
  styles: [
    `
      ::ng-deep{
        .mat-menu-content{
          background-color:#26153f
        }
      
    }`
  ]
})
export class SmallMenuComponent implements OnInit {
    @Input() user: any;
    @Input() isAuthenticated: boolean;
  constructor() { }

  ngOnInit(): void {
  }

}
