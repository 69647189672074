<ng-container *transloco="let t">
    <div class="block">
        <button mat-icon-button [matMenuTriggerFor]="userNotifications"
                [stopPropagation]="['click']" class="flex justify-center items-center" (click)="showNotification()">
            <img *ngIf="(isNewMessages | async) !== true" src="assets/icons/bell-notification.svg" alt="notification bell" class="h-7 w-7"/>
            <img *ngIf="isNewMessages| async" src="assets/icons/bell-notification-action.svg" alt="notification bell" class="h-7 w-7"/>
        </button>

        <mat-menu backdropClass="notificationMenu"
                  [xPosition]="'after'"
                  #userNotifications="matMenu" class="user-menu notificationMenu">
            <p class="text-xl text-white font-bold mt-8 ml-6">{{ 'NOTIFICATIONS' | transloco}}</p>
            <ng-container *ngIf="notifications?.allRecords > 0; else noItems">
            <app-notification-item (handleClick)="readNotification($event)" [notification]="notification"
                                   *ngFor="let notification of notifications?.data; trackBy: trackByFn"></app-notification-item>
            <button class="w-full mt-3 mb-1" *ngIf="size < notifications?.allRecords" [stopPropagation]="['click']" (click)="loadMore()">{{ 'LOAD_MORE' | transloco}}</button>
            </ng-container>
            <ng-template #noItems>
                <div class="min-w-100 px-6 py-3">{{ 'NO_ITEMS' | transloco}}</div>
            </ng-template>
        </mat-menu>
    </div>
</ng-container>
