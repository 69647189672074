<ng-container *transloco="let t">
<div class="flex justify-end">
    <button aria-label="Close Button" mat-icon-button (click)="closeDialog()">
        <mat-icon>close</mat-icon>
    </button>
</div>

<div class="flex flex-col flex-1 w-fit items-center px-18 pb-6 -mt-3 gap-6">
    <div class="bg-color-purple-100 w-14 h-14 rounded-full">
        <mat-icon class="text-white w-14 h-14 text-5xl flex justify-center items-center">logout</mat-icon>
    </div>
    <div class="text-2xl text-white">{{t('SIGNOUT_CONFIRMATION')}}?</div>
    <app-submit-button
        class="w-full"
        [label]="t('SIGNOUT')"
        buttonClass="mt-6 p-2 w-full rounded text-xl text-bold bg-transparent border-1 border-pink border-solid rounded-lg"
        (handleSubmit)="logout()"
        [loading]="!!(loading$ | async)"
    ></app-submit-button>
</div>
</ng-container>
