<button
    [type]="type"
    mat-flat-button
    [color]="color"
    [ngClass]="buttonClass"
    [class.cursor-not-allowed]="disabled || loading"
    [disabled]="disabled || loading"
    (click)="submit($event)"
    [ngStyle]="buttonStyle"
>
    <span class="flex justify-center items-center">
        <mat-icon *ngIf="icon" class="mr-2">{{icon}}</mat-icon>
        <ng-content select="[icon]"></ng-content>
  <span class="mr-2">
    {{ label }}
  </span>
        <ng-content select="[after]"></ng-content>
  <mat-progress-spinner
      *ngIf="loading"
      [diameter]="spinnerDiameter"
      [mode]="spinnerMode"
  >
  </mat-progress-spinner>
    </span>
</button>
