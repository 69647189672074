import {Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {slideInAnimation} from './animation';
import {ActivatedRoute, Router, RouterOutlet} from '@angular/router';
import {Subject} from 'rxjs';
import {FuseMediaWatcherService} from '../@fuse/services/media-watcher';
import {filter, mergeMapTo, switchMap, takeUntil} from 'rxjs/operators';
import {SharedService} from './shared/services/shared.service';
import {AuthService} from './core/auth/auth.service';
import {UserService} from './core/user/user.service';
import {TRANSLOCO_LOADER, TranslocoService} from '@ngneat/transloco';
import {TranslocoPersistTranslations} from '@ngneat/transloco-persist-translations';
import {environment} from '../environments/environment';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NAVIGATOR} from '@ng-web-apis/common';
import {AngularFireMessaging} from '@angular/fire/compat/messaging';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [
        slideInAnimation
    ],
    encapsulation: ViewEncapsulation.Emulated,
})
export class AppComponent implements OnInit, OnDestroy {

    isScreenSmall: boolean;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(private _activatedRoute: ActivatedRoute,
                private _router: Router,
                private _fuseMediaWatcherService: FuseMediaWatcherService,
                public sharedService: SharedService,
                public _authService: AuthService,
                private _userService: UserService,
                @Inject(TRANSLOCO_LOADER) private _loader: TranslocoPersistTranslations,
                private _translocoService: TranslocoService,
                private _afMessaging: AngularFireMessaging,
                private _snackbar: MatSnackBar,
                @Inject(NAVIGATOR) private _navigator: Navigator,) {
    }

    ngOnInit(): void {
        this.registerServiceWorker();
        this.hideAllConsoleLogs();
        this.selectLanguage();
        this._authService.refreshIfTokenExist().subscribe();
        this._authService.authenticated$
            .pipe(
                takeUntil(this._unsubscribeAll),
                filter(auth => auth),
                switchMap(() => this._userService.loadUser())).subscribe();

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {

                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
        //initial get category tree
        this.sharedService.getCategories().subscribe();
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    prepareRoute(outlet: RouterOutlet) {
        return outlet?.activatedRouteData?.['animation'];
    }

    clearTranslationsCache(): void {
        this._loader.clearCache();
    }

    selectLanguage(): void {
        switch (environment.lang) {
            case 'de':
                this._translocoService.setActiveLang('de');
                break;
            case 'en':
                this._translocoService.setActiveLang('en');
                break;
            default:
                this._translocoService.setActiveLang('de');
                break;
        }
    }

    hideAllConsoleLogs(): void {
        if (!window.console || !environment.production) {
            return;
        }
        const methods = ['log', 'debug', 'warn', 'info'];
        for (const method of methods) {
            // eslint-disable-next-line no-console
            console[method] = (): void => {
            };
        }
    }


    requestNotificationPermission(): void {
        this._afMessaging.requestPermission // request permission
            .pipe(mergeMapTo(this._afMessaging.tokenChanges)) // get a token
            .subscribe(
                (token) => {
                    // eslint-disable-next-line no-restricted-globals
                    localStorage.setItem('deviceToken', JSON.stringify(token));
                },
                (error) => {
                    console.error(error);
                }
            );
    }

    registerServiceWorker(): void {
        this._navigator.serviceWorker
            .register(`/firebase-messaging-sw${environment.production ? '.prod': ''}.js`)
            .then(() => {
                this.requestNotificationPermission();
            });
    }
}
