import {Component, Input} from '@angular/core';
import {Category, SubCategory, Topic} from '../../../../modules/admin/home/services/interfaces';
import {SharedService} from '../../../services/shared.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-explore',
    templateUrl: './explore.component.html',
    styleUrls: ['./explore.component.scss'],
    host: {class: 'flex-1 justify-center'}
})
export class ExploreComponent {
    @Input() categories: Category[];
    hoveredCategory: Category;
    hoveredSubCategory: SubCategory;
    timedOutCloser;

    constructor(public sharedService: SharedService,
                private router: Router) {
    }

    onTopicClicked(topic: Topic): void {
        this.router.navigateByUrl('/workshops/list?topic=' + topic.id);
    }

    onSubCategoryClicked(subCategory: SubCategory): void {
        this.router.navigateByUrl('/workshops/list?subCategory=' + subCategory.id);
    }

    onCategoryClicked(category: Category): void {
        this.router.navigateByUrl('/workshops/list?category=' + category.id);
    }

    mouseEnter(trigger): void {
        if (this.timedOutCloser) {
            clearTimeout(this.timedOutCloser);
        }
        if (!trigger.menuOpen) {
            trigger.openMenu();
        }
    }

    mouseLeave(trigger, time = 200): void {
        if (!trigger.menuOpen) {
            return;
        }
        this.timedOutCloser = setTimeout(() => {
            trigger.closeMenu();
        }, time);
    }

}
