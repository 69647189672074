<ng-container *transloco="let t">
    <div>
        <ng-container *ngIf="isAuthenticated">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <img alt="avatar"
                     class="w-9 h-9 rounded-full"
                     [src]="user?.imageUrl">
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item routerLink="bookings">
                    <span>{{t('MY_BOOKINGS')}}</span>
                </button>
                <button mat-menu-item routerLink="settings/list">
                    <span>{{t('SETTINGS')}}</span>
                </button>
            </mat-menu>
        </ng-container>

        <ng-container *ngIf="!isAuthenticated">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon class="text-white">menu</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item routerLink="/auth/account">
                    <span>{{t('JOIN_NOW')}}</span>
                </button>
                <button mat-menu-item routerLink="/instructor-promoted">
                    <span>{{t('BE_AN_INSTRUCTOR.BECOME_AN_INSTRUCTOR')}}</span>
                </button>
            </mat-menu>
        </ng-container>
    </div>
</ng-container>
