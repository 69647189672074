import {ChangeDetectionStrategy, Component} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {SharedService} from '../../services/shared.service';
import {AuthService} from '../../../core/auth/auth.service';

@Component({
    selector: 'app-logout-dialog',
    templateUrl: './logout-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoutDialogComponent {
    loading$ = new BehaviorSubject(false);

    constructor(private _dialogRef: MatDialogRef<LogoutDialogComponent>,
                private _authService: AuthService, private _router: Router,
                private _sharedService: SharedService) {}

    static dialogConfig(): MatDialogConfig {
        return {
            panelClass: 'no-padding-dialog',
            disableClose: false,
            autoFocus: false,
            maxWidth: '38rem',
        };
    }

    logout(): void {
        this.loading$.next(true);
        this._authService.signOut().subscribe({
            next: () => {
                this.loading$.next(false);
                this.closeDialog();
                this._sharedService.removeCookie('accessToken');
                this._authService.refresh();
                this._router.navigate(['home']);
            },
            error: () => {
                this.loading$.next(false);
            }
        });
    }

    closeDialog(): void {
        this._dialogRef.close();
    }

}
