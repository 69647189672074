import {Directive} from '@angular/core';
import {ControlValueAccessor, NgControl} from '@angular/forms';

@Directive({selector: '[inputRefTrim]'})
export class InputRefDirective {
    constructor(private ngControl: NgControl) {
        this.trimValueAccessor(ngControl.valueAccessor);
    }

    trimValueAccessor(valueAccessor: ControlValueAccessor): void {
        const original = valueAccessor.registerOnChange;

        valueAccessor.registerOnChange = (fn: (_: unknown) => void) =>
            original.call(valueAccessor, (value: unknown) => fn(typeof value === 'string' ? value.trim() : value));
    }
}
