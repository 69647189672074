import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import {ThemePalette} from '@angular/material/core';
import {ProgressSpinnerMode} from '@angular/material/progress-spinner';

@Component({
  selector: 'app-submit-button',
  templateUrl: './button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent {
  @Input() disabled = false;
  @Input() label = 'Submit';
  @Input() loading = false;
  @Input() spinnerMode: ProgressSpinnerMode = 'indeterminate';
  @Input() spinnerDiameter = 24;
  @Input() color: ThemePalette = 'primary';
  @Input() buttonClass = '';
  @Input() icon;
  @Input() buttonStyle;
  @Input() type = 'button';
  @Output() handleSubmit = new EventEmitter<MouseEvent>();

  constructor() {
  }

  submit(event: MouseEvent): void {
    this.handleSubmit.emit(event);
  }

}
