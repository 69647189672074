import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';
import {UserInterface} from '../../../models/user.interface';

@Component({
    selector: 'app-notification-toast',
    templateUrl: './notification-toast.component.html',
    host: {class: 'bg-color-purple-600'}

})
export class NotificationToastComponent implements OnInit {

    constructor(@Inject(MAT_SNACK_BAR_DATA) public notification: UserInterface.InAPPNotification) {
    }

    ngOnInit(): void {
    }

}
