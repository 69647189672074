export const environment = {
    firebase: {
        projectId: 'tantetobilive',
        appId: '1:948544586704:web:837dfc3706c73f1487afd5',
        storageBucket: 'tantetobilive.appspot.com',
        apiKey: 'AIzaSyCKvrrKls1QVeZLXr6IhISqpTpEva8CVoU',
        authDomain: 'tantetobilive.firebaseapp.com',
        messagingSenderId: '948544586704',
        measurementId: 'G-CZNZSQM9LJ',
    },
    lang: 'en',
    production: false,
    APIUrl: 'https://api-dev.tantetobi.de/tantetobi/api/v1/',
    STRIP_PUBLISHABLE_KEY: 'pk_test_51KfinfDNy18F7h1vLx3YN5KjwCRlEVhTUgCtSe68mGuzv7zutFDNlmTcKfuTlCwa4mTI6avpe13XQWq7AgmklS2M00nmGlz8b3',
    appVersion: require('../../package.json').version,
    instructorDashboardUrl: ' https://instructor-dev.tantetobi.de/',
};
