<!-- Bar search -->
<ng-container *ngIf="appearance === 'bar'">
    <button
        mat-icon-button
        *ngIf="!opened"
        (click)="open()">
        <mat-icon [svgIcon]="'heroicons_outline:search'" class="mr-4 text-white"></mat-icon>
    </button>
    <div
        class="absolute inset-0 flex items-center flex-shrink-0 z-99 auto-complete h-20"
        [style]="barStyles"
        *ngIf="opened"
        @slideInTop
        @slideOutTop>
        <mat-icon
            class="absolute ml-6 sm:ml-8 text-white mr-2"
            [svgIcon]="'heroicons_outline:search'"></mat-icon>
        <input
            class="w-full h-full px-16 sm:px-18 text-white"
            [formControl]="searchControl"
            [matAutocomplete]="matAutocomplete"
            [placeholder]="'Search'"
            (keydown)="onKeydown($event)"
            style="background-color: #3C2363">
        <mat-autocomplete
            class="max-h-128 mt-1 rounded bg-color-purple-600"
            [disableRipple]="true"
            (optionSelected)="onOptionSelected($event?.option?.value?.workshopName)"
            [displayWith]="displayFn"
            #matAutocomplete="matAutocomplete">
            <mat-option
                class="py-0 px-6 text-md pointer-events-none text-secondary bg-transparent"
                *ngIf="resultSets && !resultSets.length">
                No results found!
            </mat-option>
            <mat-option *ngFor="let option of resultSets" [value]="option">
            <div class="flex flex-row"> 
            <mat-icon class="mt-3 text-white opacity-12">search</mat-icon>  
            <span>{{option.workshopName}}</span>
        </div>
            </mat-option>
        </mat-autocomplete>
        <button
            class="absolute top-1/2 right-5 sm:right-7 flex-shrink-0 w-10 h-10 -mt-5 text-white"
            mat-icon-button
            (click)="close()">
            <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>
</ng-container>

<!-- Basic search -->
<ng-container *ngIf="appearance === 'basic'">
    <div class="w-full sm:min-w-80 auto-complete basic">
        <mat-form-field class="fuse-mat-no-subscript w-full">
            <mat-icon
                matPrefix class="text-white mr-2"
                [svgIcon]="'heroicons_outline:search'"></mat-icon>
            <input
                matInput
                #searchInput
                type="text"
                class="text-white"
                [placeholder]="placeholder"
                [formControl]="searchControl"
                [matAutocomplete]="matAutocomplete"
                (keydown.enter)="onOptionSelected(searchInput.value)"
                (keydown)="onKeydown($event)">
        </mat-form-field>
        <mat-autocomplete
            class="max-h-128 mt-1 rounded bg-color-purple-600"
            [disableRipple]="true"
            (optionSelected)="onOptionSelected($event?.option?.value?.workshopName)"
            [displayWith]="displayFn"
            #matAutocomplete="matAutocomplete">
            <mat-option
                class="py-0 px-6 text-md pointer-events-none text-secondary bg-transparent"
                *ngIf="resultSets && !resultSets.length">
                No results found!
            </mat-option>
            <mat-option *ngFor="let option of resultSets" [value]="option">
               <div class="flex flex-row"> 
            <mat-icon class="mt-3 text-white opacity-12">search</mat-icon>  
            <span>{{option.workshopName}}</span>
        </div>
            </mat-option>
        </mat-autocomplete>
    </div>
</ng-container>
