import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserInterface} from '../../../models/user.interface';
import {UserService} from '../../../../core/user/user.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-notification-item',
    templateUrl: './notification-item.component.html'
})
export class NotificationItemComponent implements OnInit {
    @Input() notification: UserInterface.Notification;
    @Output() handleClick = new EventEmitter<UserInterface.Notification>();


    constructor(private _userService: UserService, private _router: Router) {
    }

    ngOnInit(): void {
    }

}
