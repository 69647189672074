import {EventEmitter, Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {Workshop} from '../../modules/admin/landing/services/interfaces';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Category} from '../../modules/admin/home/services/interfaces';
import {tap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class SharedService {

    updateGlobalSearch = new EventEmitter();
    filteredOptions: Workshop[];
    getCategoriesTreeHeader = new EventEmitter();
    private _categoriesTree = new BehaviorSubject([]);
    private _canJoinNow = true;
    private _showFooter = true;
    private _showCustomHeader = true;
    private _showHeader = true;
	private _footerColor = '';
    private _showLoggedHeader = false;

    constructor(private http: HttpClient) {
    }

    get categoriesTree(): Observable<Category[]> {
        return this._categoriesTree.asObservable();
    }

    updateCategoriesTree(categoriesTree): void {
        this._categoriesTree.next(categoriesTree);
    }

    get canJoinNow(): boolean {
        return this._canJoinNow;
    }

    set canJoinNow(val: boolean) {
        this._canJoinNow = val;
    }

    get showFooter(): boolean {
        return this._showFooter;
    }

    set showFooter(val: boolean) {
        this._showFooter = val;
    }

    get showCustomHeader(): boolean {
        return this._showCustomHeader;
    }

    set showCustomHeader(val: boolean) {
        this._showCustomHeader = val;
    }

    get showHeader(): boolean {
        return this._showHeader;
    }

    set showHeader(val: boolean) {
        this._showHeader = val;
    }
    get footerColor(): string {
        return this._footerColor;
    }

    set footerColor(val: string) {
        this._footerColor = val;
    }

    get showLoggedHeader(): boolean {
        return this._showLoggedHeader;
    }

    set showLoggedHeader(val: boolean) {
        this._showLoggedHeader = val;
    }

    configureFooterAndHeader(showCustomHeader: boolean, canJoinNow: boolean, showFooter: boolean,
                             showHeader: boolean = true, showLoggedHeader=  false, footerColor: string = ''): void {
        Promise.resolve().then(() => {
            this.showHeader = showHeader;
            this.showCustomHeader = showCustomHeader;
            this.canJoinNow = canJoinNow;
            this.showFooter = showFooter;
            this.footerColor = footerColor;
            this.showLoggedHeader = showLoggedHeader;
        });
    }

    searchWorkshop(searchKey: string): Observable<any> {
        return this.http.get<Workshop>(environment.APIUrl + 'user/workshops/search?subject=' + searchKey);
    }

    //temp solution until we revamp workshop list component, they are changing the data response itself
    getHomePageCategories(page: number = 0, size: number = 20): Observable<any> {
        return this.http.get<Array<Category>>(environment.APIUrl + 'user/categories-tree');
    }

    getCategories(page: number = 0, size: number = 20): Observable<any> {
        return this.http.get<Array<Category>>(environment.APIUrl + 'user/categories-tree')
            .pipe(tap((response) => {
                this.updateCategoriesTree(response.data);
                return of(response);
            }));
    }

    fileUpload(file): Observable<any> {
        return this.http.post(environment.APIUrl + 'media/upload', file);
    }

    setCookie(cname, cvalue, exdays, isSecure = false): void {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        const expires = 'expires='+d.toUTCString();
        document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/;' + 'Secure=' + isSecure + ';';
    }

    getCookie(cname): string {
        const name = cname + '=';
        const ca = document.cookie.split(';');
        for (let c of ca) {
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return 'undefined';
    }

    removeCookie(cname): void {
        document.cookie = cname + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    }
}
