import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LogoutDialogComponent} from './logout-dialog.component';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {ButtonModule} from '../button/button.module';
import {TranslocoModule} from "@ngneat/transloco";



@NgModule({
  declarations: [LogoutDialogComponent],
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        ButtonModule,
        TranslocoModule
    ]
})
export class LogoutDialogModule { }
