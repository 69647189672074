<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full">

    <app-header *ngIf="sharedService.showHeader"></app-header>

    <!-- Content -->
    <div class="w-full h-full min-app-height flex flex-col" [@routeAnimations]="prepareRoute(outlet)">
        <router-outlet #outlet="outlet"></router-outlet>

    </div>

    <!-- Footer -->
   <app-footer *ngIf="sharedService.showFooter"></app-footer>

</div>

