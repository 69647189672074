import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserService} from '../../../core/user/user.service';
import {Router} from '@angular/router';
import {UserInterface} from '../../models/user.interface';
import {NotificationToastComponent} from './notification-toast/notification-toast.component';
import {AngularFireMessaging} from '@angular/fire/compat/messaging';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: 'app-notifications-menu',
    templateUrl: './notifications-menu.component.html',
    host: {class: 'block'}
})
export class NotificationsMenuComponent implements OnInit {
    @Input() notifications: UserInterface.Notifications;
    @Output() refresh = new EventEmitter<number>();
    size = 20;
    isNewMessages = new BehaviorSubject(false);

    constructor(private _userService: UserService,
                private _router: Router,
                private _afMessaging: AngularFireMessaging,
                private _snackbar: MatSnackBar) {
    }

    ngOnInit(): void {
        const isNewMessage = JSON.parse(localStorage.getItem('newNotifications') || 'null');
        this.listenToNewMessages();
        this.isNewMessages.next(isNewMessage);
    }

    readNotification(notification: UserInterface.Notification): void {
        this._userService.readNotifications([notification.id]).subscribe();
        if (notification.notificationCategory === 'BOOKING') {
            this._router.navigate(['workshops', 'view-booking', notification.notificationPayload.bookingId]);
        }
        if (notification.notificationCategory === 'INSTRUCTOR_WORKSHOP') {
            this._router.navigate(['workshops', 'details'], {queryParams: {id: notification.notificationPayload.bookingId}});
        }
        this.refresh.emit();
    }

    trackByFn(index, notification: UserInterface.Notification): number {
        return notification.id;
    }

    loadMore(): void {
        this.size = this.size + 20;
        this.refresh.emit(this.size);
    }


    listenToNewMessages(): void {
        // Follow here for more info: https://github.com/angular/angularfire/blob/master/docs/messaging/messaging.md
        this._afMessaging.messages.subscribe((payload) => {
            localStorage.setItem('newNotifications', JSON.stringify(true));
            this.refresh.emit(this.size);
            this.isNewMessages.next(true);
            this._snackbar.openFromComponent(NotificationToastComponent,
                {data: payload.notification, duration: 3000, horizontalPosition: 'right', panelClass: 'bg-color-purple-600'});
        });
    }

    showNotification(): void {
        this.isNewMessages.next(false);
        localStorage.setItem('newNotifications', JSON.stringify(false));
    }

}
