<ng-container *transloco="let t">
<div class="page-not-found">
    <div class="pt-44 md:flex">
        <div class="w-full md:w-1/2">
            <div class="text-404 flex flex-col items-center">
                <div class="pt-20 sm:pt-28 md:w-80 xl:w-auto text-center md:text-left">
                    <div class="header-text mb-8">
                        404
                    </div>
                    <div class="something-went-wrong uppercase mb-28 md:mb-16 mb-8">
                        {{t('ERRORS.SOMETHING_WENT')}}
                        <div class="txt-purple-100 font-bold">{{t('ERRORS.WRONG')}}!</div>
                    </div>
                    <div class="w-full md:w-auto px-6 md:px-0">
                        <button class="fuse-mat-button-large w-full md:w-60" mat-flat-button
                                [color]="'primary'" routerLink="/home">
                            {{t('HOME_PAGE')}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="w-1/2 overflow-hidden hidden md:block">
            <div class="robot-img"></div>
        </div>
    </div>
</div>
</ng-container>
