<ng-container *transloco="let t">

    <!--unlogged users header-->
    <div class="flex flex-0 items-center w-full px-4 py-5 lg:px-32 unLogged-header" style="z-index: 2"
         [ngClass]="{ 'hidden': sharedService.showCustomHeader || sharedService.showLoggedHeader }">
        <div class="flex flex-1">
            <div class="lg:flex">
                <img
                    class="w-24 animate__animated animate__fadeIn cursor-pointer"
                    src="assets/images/logo/logo.svg" routerLink="/home">
            </div>
            <app-explore class="hidden xl:flex" [categories]="categories$ | async"></app-explore>
        </div>

        <div class="h-10 w-140 flex-1 hidden xl:block">
            <search [appearance]="'basic'" placeholder="{{t('SEARCH_FOR_A_WORKSHOP')}}"
                    (search)="searchForWorkshop($event)"></search>
        </div>
        <div class="flex-auto text-right md:ml-72 block xl:hidden">
            <search [appearance]="'bar'" placeholder="{{t('SEARCH_FOR_A_WORKSHOP')}}"
                    (search)="searchForWorkshop($event)"
                    [barStyles]="'top: -36px !important'"></search>
        </div>

        <app-small-menu class="block md:hidden"
                        [isAuthenticated]="authService.authenticated$ | async"
                        [user]="_userService.user"></app-small-menu>

        <div class="join-button hidden sm:hidden md:flex flex-1 flex items-center justify-around">
            <button class="font-bold text-white bg-transparent" mat-button *ngIf="authService.accessToken === 'undefined'" routerLink="/instructor-promoted">{{t('BE_AN_INSTRUCTOR.BECOME_AN_INSTRUCTOR')}}</button>
            <button class="font-bold" mat-raised-button *ngIf="authService.accessToken === 'undefined'"
                    [ngClass]="{ 'show': sharedService.canJoinNow }" routerLink="/auth/account">{{t('JOIN_NOW')}}
            </button>
            <app-user-menu *ngIf="authService.authenticated$ | async" [user]="_userService.user"></app-user-menu>
        </div>

    </div>

    <!--logged user header for pages such as bookings, workshops-->
    <div class="relative flex flex-0 justify-between items-center w-full h-16 sm:h-20 px-4 xl:px-30 z-49 custom-header"
         *ngIf="!(!sharedService.showCustomHeader || sharedService.showLoggedHeader)">
        <div class="flex flex-1">
            <div class="lg:flex">
                <img
                    class="w-24 animate__animated animate__fadeIn cursor-pointer"
                    src="assets/images/logo/logo.svg" routerLink="/home">
            </div>
            <app-explore class="hidden xl:flex" [categories]="categories$ | async"></app-explore>
        </div>
        <div class="h-13 w-140 flex-1 hidden xl:block">
            <search [appearance]="'basic'" placeholder="{{t('SEARCH_FOR_A_WORKSHOP')}}"
                    (search)="searchForWorkshop($event)"></search>
        </div>
        <div class="flex-1 text-right block xl:hidden">
            <search [appearance]="'bar'" placeholder="{{t('SEARCH_FOR_A_WORKSHOP')}}"
                    (search)="searchForWorkshop($event)"></search>
        </div>
        <div class="flex gap-6 items-center flex-1 justify-end">
            <div>
            <app-notifications-menu *ngIf="authService.authenticated$ | async" [notifications]="notifications$ | async"
                                    (refresh)="triggerRefresh.next($event)"></app-notifications-menu>
            </div>
            <app-small-menu class="block xl:hidden {{sharedService.canJoinNow ? 'show' : 'hidden' }}"
                            [isAuthenticated]="authService.authenticated$ | async"
                            [user]="_userService.user"></app-small-menu>
            <div class="join-button hidden sm:hidden xl:flex flex items-center justify-end">
                <button class="font-bold" mat-raised-button [ngClass]="{ 'show': sharedService.canJoinNow }"
                        *ngIf="authService.accessToken === 'undefined'" routerLink="/auth/account">{{t('JOIN_NOW')}}
                </button>
                <app-user-menu *ngIf="authService.authenticated$ | async" [user]="_userService.user"></app-user-menu>
            </div>
        </div>
    </div>

    <!--homepage header-->
    <div class="relative flex flex-0 items-center w-full h-0 px-4 lg:px-32 top-9 logged-header" style="z-index: 2"
         *ngIf="sharedService.showLoggedHeader">
        <!-- Logo -->
        <div class="flex flex-1">
            <div class="lg:flex">
                <img
                    class="w-24 animate__animated animate__fadeIn cursor-pointer"
                    src="assets/images/logo/logo.svg" routerLink="/home">
            </div>
            <app-explore class="hidden xl:flex" [categories]="categories$ | async"></app-explore>
        </div>

        <div class="h-10 w-140 flex-1 hidden xl:block">
            <search [appearance]="'basic'" placeholder="{{t('SEARCH_FOR_A_WORKSHOP')}}"
                    (search)="searchForWorkshop($event)"></search>
        </div>
        <div class="flex-auto text-right block xl:hidden">
            <search [appearance]="'bar'" placeholder="{{t('SEARCH_FOR_A_WORKSHOP')}}"
                    (search)="searchForWorkshop($event)"
                    [barStyles]="'top: -36px !important'"></search>
        </div>

        <div class="flex gap-6 items-center flex-1 justify-end">
            <app-notifications-menu *ngIf="authService.authenticated$ | async" [notifications]="notifications$ | async" (refresh)="triggerRefresh.next($event)"></app-notifications-menu>
            <app-small-menu class="block xl:hidden {{sharedService.canJoinNow ? 'show' : 'hidden' }}"
                            [isAuthenticated]="authService.authenticated$ | async"
                            [user]="_userService.user"></app-small-menu>

            <div class="join-button hidden sm:hidden xl:flex flex items-center justify-end">
                <app-user-menu [user]="_userService.user"></app-user-menu>
            </div>
        </div>

    </div>
</ng-container>
