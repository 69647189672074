import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {ApplicationUser} from '../../../core/user/user.types';

@Component({
    selector: 'app-user-menu',
    templateUrl: './user-menu.component.html',
    changeDetection: ChangeDetectionStrategy.Default
})
export class UserMenuComponent implements OnInit {
    @Input() user: ApplicationUser;

    constructor() {
    }

    ngOnInit(): void {
    }

}
