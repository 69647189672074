import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, ReplaySubject} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {ApplicationUser} from 'app/core/user/user.types';
import {environment} from '../../../environments/environment';
import {BaseResponse} from '../../shared/models/response.interface';
import {UserInterface} from '../../shared/models/user.interface';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private _user: ReplaySubject<ApplicationUser> = new ReplaySubject<ApplicationUser>(1);
    private instantUser: ApplicationUser;

    constructor(private _httpClient: HttpClient) {
    }

    set user(value: ApplicationUser) {
        this._user.next(value);
        this.instantUser = value;
    }

    get user(): ApplicationUser {
        return this.instantUser;
    }

    get user$(): Observable<ApplicationUser> {
        return this._user.asObservable();
    }


    getUser(): Observable<ApplicationUser> {
        return this._httpClient.get<BaseResponse<ApplicationUser>>(environment.APIUrl + 'user/profile').pipe(
            map(response => response.data));
    }

    loadUser(): Observable<ApplicationUser> {
        return this.getUser()
            .pipe(tap((user) => {
                this._user.next(user);
                this.user = user;
            }));
    }

    contactUs(form: UserInterface.IContactUsForm): Observable<any> {
        return this._httpClient.post(environment.APIUrl + 'client/clients/contact', form);
    }

    getNotifications({size = 20}): Observable<UserInterface.Notifications> {
        const params = new HttpParams().append('size', size);
        return this._httpClient.get<UserInterface.Notifications>(environment.APIUrl + 'user/notifications', {params});
    }

    readNotifications(notificationIds: number[]): Observable<any> {
        return this._httpClient.patch(environment.APIUrl + 'user/notifications/read', {notificationIds});
    }
}
