import {NgModule} from '@angular/core';
import {FuseDrawerModule} from '@fuse/components/drawer';
import {LayoutComponent} from 'app/layout/layout.component';
import {SettingsModule} from 'app/layout/common/settings/settings.module';
import {SharedModule} from 'app/shared/shared.module';

@NgModule({
    declarations: [
        LayoutComponent
    ],
    imports: [
        FuseDrawerModule,
        SharedModule,
        SettingsModule
    ],
    exports: [
        LayoutComponent
    ]
})
export class LayoutModule {
}
