import {Route} from '@angular/router';
import {NotFoundComponent} from './shared/components/not-found/not-found.component';
import {NoAuthGuard} from './core/auth/guards/noAuth.guard';

export const appRoutes: Route[] = [

    {path: '', pathMatch: 'full', redirectTo: 'home'},
    {path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'home'},
    {
        path: '',
        loadChildren: () => import('app/modules/admin/static-pages/static-pages.module').then(m => m.StaticPagesModule)
    },
    {
        path: 'auth',
        canActivate: [NoAuthGuard],
        loadChildren: () => import('app/modules/auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: 'instructor-sign-up',
        loadChildren: () => import('app/modules/auth/instructor-sign-up/instructor-sign-up.module').then(m => m.AuthInstructorSignUpModule)
    },
    {
        path: 'home',
        loadChildren: () => import('app/modules/admin/home/home.module').then(m => m.HomeModule),
        data: {animation: 'HomePage'}
    },
    {
        path: 'instructor-promoted',
        loadChildren: () => import('app/modules/admin/instructor-promoted/instructor-promoted.module').then(m => m.InstructorPromotedModule),
    },
    {
        path: 'zertifikat',
        loadChildren: () => import('app/modules/admin/certificate/certificate.module').then(m => m.CertificateModule),
    },
    {
        path: 'workshops',
        loadChildren: () => import('app/modules/admin/workshops/workshops.module').then(m => m.WorkshopsModule)
    },
    {
        path: 'instructor-profile',
        loadChildren: () => import('app/modules/admin/instructor-profile/instructor-profile.module').then(m => m.InstructorProfileModule)
    },
    {
        path: 'contact-us',
        loadChildren: () => import('app/modules/admin/contact-us/contact-us.module').then(m => m.ContactUsModule)
    },
    {
        path: 'bookings',
        loadChildren: () => import('app/modules/admin/booking/booking-list/booking-list.module').then(m => m.BookingListModule)
    },
    {
        path: 'settings',
        loadChildren: () => import('app/modules/admin/settings/settings.module').then(m => m.SettingsModule)
    },
    {
        path: 'faq',
        loadChildren: () => import('app/modules/admin/faq/faq.module').then(m => m.FaqModule)
    },
    {path: '**', pathMatch: 'full', component: NotFoundComponent},
];
