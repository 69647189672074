<ng-container *transloco="let t">
<button *ngIf="user" class="w-max" #menuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="userActions">
    <span class="flex gap-3 items-center">
        <img alt="avatar"
            class="w-9 h-9 rounded-full"
            [src]="user.imageUrl">
        <span class="text-lg font-bold text-white hidden lg:block">{{user.fullName}}</span>
        <span class="flex min-w-6 hidden lg:block">
        <mat-icon *ngIf="menuTrigger.menuOpen" class="text-2xl font-bold text-white">expand_more</mat-icon>
        </span>
    </span>
</button>

<mat-menu
    [xPosition]="'before'"
    #userActions="matMenu" class="user-menu">
    <button mat-menu-item routerLink="bookings">
        <span>{{t('MY_BOOKINGS')}}</span>
    </button>
    <button mat-menu-item routerLink="settings/list">
        <span>{{t('SETTINGS')}}</span>
    </button>
</mat-menu>
</ng-container>
