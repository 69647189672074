<div class="rating-star">
    <ng-container *ngIf="rating === 0; else ratingStar">
        <a title="rating">
            <img alt="star" width="25px" height="25px" src="assets/icons/star.svg"/>
        </a>
        <span class="text-md text-white">NEU</span>
    </ng-container>
    <ng-template #ratingStar>
        <div class="flex gap-1 items-center">
            <a title="rating" class="checked">
                <img alt="star" width="25px" height="25px" src="assets/icons/star.svg"/>
            </a>
            <span class="font-bold text-lg text-yellow-500">{{ rating }}</span>
        </div>
    </ng-template>
</div>
