import {
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, filter, map, takeUntil} from 'rxjs/operators';
import {fuseAnimations} from '@fuse/animations/public-api';
import {SharedService} from "../../../shared/services/shared.service";
import {Workshop} from "../../../modules/admin/landing/services/interfaces";

@Component({
    selector: 'search',
    templateUrl: './search.component.html',
    encapsulation: ViewEncapsulation.None,
    exportAs: 'fuseSearch',
    animations: fuseAnimations
})
export class SearchComponent implements OnInit, OnDestroy {
    @Input() appearance: 'basic' | 'bar' = 'basic';
    @Input() debounce: number = 300;
    @Input() minLength: number = 4;
    @Output() search: EventEmitter<any> = new EventEmitter<any>();
    @Input() placeholder = '';
    @Input() barStyles = '';

    opened: boolean = false;
    resultSets: Workshop[];
    searchControl: FormControl = new FormControl();
    subscriber: Subscription;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private sharedService: SharedService
    ) {
    }

    /**
     * Host binding for component classes
     */
    @HostBinding('class') get classList(): any {
        return {
            'search-appearance-bar': this.appearance === 'bar',
            'search-appearance-basic': this.appearance === 'basic',
            'search-opened': this.opened
        };
    }

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to the search field value changes
        this.searchControl.valueChanges
            .pipe(
                debounceTime(this.debounce),
                takeUntil(this._unsubscribeAll),
                map((value) => {

                    // Set the resultSets to null if there is no value or
                    // the length of the value is smaller than the minLength
                    // so the autocomplete panel can be closed
                    if (!value || value.length < this.minLength) {
                        this.resultSets = null;
                    }

                    // Continue
                    return value;
                }),
                // Filter out undefined/null/false statements and also
                // filter out the values that are smaller than minLength
                filter(value => value && value.length >= this.minLength)
            )
            .subscribe((value) => {
                if (value && value.length >= 4) {
                    this.sharedService.searchWorkshop(value)
                        .subscribe(({data}) => {
                            this.resultSets = data;
                        });
                } else {
                    this.resultSets = null;
                }
            });

        this.subscriber = this.sharedService.updateGlobalSearch.subscribe(value => {
            if (value.length) {
                this.searchControl.setValue({workshopName: value});
            } else {
                this.searchControl.setValue('');
            }
        })
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        this.subscriber.unsubscribe();
    }

    /**
     * On keydown of the search input
     *
     * @param event
     */
    onKeydown(event: KeyboardEvent): void {
        // Listen for escape to close the search
        // if the appearance is 'bar'
        if (this.appearance === 'bar') {
            // Escape
            if (event.code === 'Escape') {
                // Close the search
                this.close();
            }
        }
    }

    /**
     * Open the search
     * Used in 'bar'
     */
    open(): void {
        // Return if it's already opened
        if (this.opened) {
            return;
        }

        // Open the search
        this.opened = true;
    }

    /**
     * Close the search
     * * Used in 'bar'
     */
    close(): void {
        // Return if it's already closed
        if (!this.opened) {
            return;
        }

        // Clear the search input
        this.searchControl.setValue('');

        // Close the search
        this.opened = false;
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    onOptionSelected(workshopName: string) {
        this.search.emit(workshopName);
    }

    displayFn(workshop: Workshop): string {
        return workshop && workshop.workshopName ? workshop.workshopName : '';
    }
}
