<div class="footer">
    <div class="py-20 px-10  {{_sharedService.footerColor}}">
        <div class="content text-white py-20 px-10 lg:px-48 grid grid-cols-12 md:gap-x-20 lg:gap-14">
            <div class="lg:col-span-4 text-white col-span-12 md:col-span-6 mb-14 lg:mb-0">
                <div class="logo">
                    <img src="assets/images/logo/logo.svg" alt="tantetobi logo"/>
                </div>
                <div class="description">{{(footerModel.description | transloco)}}</div>
                <div class="flex">
                    <div class="partner-logo mr-6 mb-3" *ngFor="let partner of footerModel.partnerLogos">
                        <img src="{{partner}}" alt="logo">
                    </div>
                </div>
                <!-- TrustBox widget - Review Collector -->
                <div id="trustbox" class="trustpilot-widget mt-4" style="margin-left: -43px" data-locale="de-DE" data-template-id="56278e9abfbbba0bdcd568bc"
                     data-businessunit-id="63047e2186ff74d864c59a7b" data-style-height="70px" data-style-width="auto">
                    <a href="https://de.trustpilot.com/review/tantetobi.de" target="_blank"
                       rel="noopener">Trustpilot</a>

                </div>
                <!-- End TrustBox widget -->
            </div>
            <div class="company text-white lg:col-span-3 col-span-12 md:col-span-5 mb-14 lg:mb-0">
                <div class="font-bold text-2xl">
                    <span>{{(footerModel.companyInformation.title | transloco)}}</span>
                </div>
                <div class="divider"></div>
                <div class="item" *ngFor="let item of footerModel.companyInformation.links">
                    <ng-container *ngIf="item.type !== 'href'; else href">
                        <span class="cursor-pointer" [routerLink]="item.link">{{(item.name | transloco)}}</span>
                    </ng-container>
                    <ng-template #href>
                        <a [href]="sanitize(item.link)" target="_blank">{{(item.name | transloco)}}</a>
                    </ng-template>
                </div>
            </div>
            <div class="company text-white lg:col-span-2 col-span-12 md:col-span-6 mb-14 lg:mb-0">
                <div class="font-bold text-2xl">
                    <span>{{(footerModel.companyPolicies.title | transloco)}}</span>
                </div>
                <div class="divider"></div>
                <div class="item" *ngFor="let item of footerModel.companyPolicies.links">
                    <span class="cursor-pointer" [routerLink]="item.link">{{(item.name | transloco)}}</span>
                </div>
            </div>
            <div class="text-white contact lg:col-span-3 md:col-span-5 col-span-12 mb-14">
                <div class="font-bold text-2xl">
                    <span>{{(footerModel.contactInformation.title | transloco)}}</span>
                </div>
                <div class="divider"></div>
                <div class="mb-8">
                    <div class="item" *ngFor="let item of footerModel.contactInformation.information">
                        <div>{{item}}</div>
                    </div>
                </div>
                <div class="flex">
                    <div class="social-media-link"
                         *ngFor="let item of footerModel.contactInformation.socialMediaLinks">
                        <a href="{{item.link}}">
                            <img src="{{item.logo}}" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="copy-right">
        &copy; {{currentYear}} {{('TANTETOBI_ALL_RIGHTS_RESERVED' | transloco)}}.
    </div>
</div>
