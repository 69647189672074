import {SharedService} from '../../services/shared.service';
import {Component, OnInit} from '@angular/core';
import {TranslocoService} from '@ngneat/transloco';
import {environment} from '../../../../environments/environment';
import {DomSanitizer} from '@angular/platform-browser';
import {Router} from '@angular/router';
// eslint-disable-next-line @typescript-eslint/naming-convention
declare const Trustpilot: any;

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    footerModel;

    constructor(public _sharedService: SharedService,
                private translocoService: TranslocoService,
                private sanitizer: DomSanitizer,
                private router: Router) {
    }

    ngOnInit(): void {
        const trustboxRef = document.getElementById('trustbox');
        if (typeof Trustpilot !== 'undefined') {
        Trustpilot.loadFromElement(trustboxRef);
        }
        this.footerModel = {
            description: 'FOOTER.DESCRIPTION',
            partnerLogos: ['../../../../assets/images/home/partner-2.png'],
            companyInformation: {
                title: 'FOOTER.ABOUT_TANTETOBI',
                links: [
                    {
                        name: 'FOOTER.ABOUT_US',
                        link: '/about-us'
                    },
                    {
                        name: 'INSTRUCTOR_INFO.CERTIFICATIONS',
                        link: '/zertifikat'
                    },
                    {
                        name: 'FAQ',
                        link: '/faq'
                    },
                    {
                        name: 'FOOTER.BE_AN_INSTRUCTOR',
                        link: '/instructor-promoted'
                    },
                    {
                        name: 'FOOTER.CONTACT_US',
                        link: '/contact-us'
                    },
                    {
                        name: 'FOOTER.INSTRUCTOR_DASHBOARD',
                        link: environment.instructorDashboardUrl,
                        type: 'href'
                    }
                ]
            },
            companyPolicies: {
                title: 'FOOTER.OUR_POLICES',
                links: [
                    {
                        name: 'FOOTER.IMPRINT',
                        link: '/imprint'
                    },
                    {
                        name: 'FOOTER.RIGHT_OF_WITHDRAWAL',
                        link: '/right-of-withdrawal'
                    },
                    {
                        name: 'FOOTER.CONDITIONS',
                        link: '/conditions'
                    },
                    {
                        name: 'FOOTER.PRIVACY',
                        link: '/privacy'
                    }
                ]
            },
            contactInformation: {
                title: 'FOOTER.CONTACT_US',
                information: ['family@tantetobi.de', 'Neumannstraße 27', '40235 Düsseldorf'],
                socialMediaLinks: [
                    {
                        logo: '../../../../assets/images/home/facebook.png',
                        link: 'https://www.facebook.com/Tante-Tobi-108296308376460'
                    },
                    {
                        logo: '../../../../assets/images/home/twitter.png',
                        link: 'https://twitter.com/tantetobide'
                    },
                    {
                        logo: '../../../../assets/images/home/linkedin.png',
                        link: 'https://www.linkedin.com/company/tante-tobi'
                    },
                    {
                        logo: '../../../../assets/images/home/instagram.png',
                        link: 'https://www.instagram.com/tantetobi.de/'
                    }
                ]
            }
        };
    }

    sanitize(url: string) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    get currentYear(): number {
        return new Date().getFullYear();
    }

}
