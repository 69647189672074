<ng-container *transloco="let t">
    <div class="flex justify-center cursor-pointer" [matMenuTriggerFor]="categoriesMenu"
         #menuTrigger="matMenuTrigger" (mouseenter)="mouseEnter(menuTrigger)">
        <span [style.color]="'white'">{{t('EXPLORE')}}</span>
        <mat-icon [style.color]="'white'">expand_more</mat-icon>
    </div>
    <mat-menu #categoriesMenu="matMenu" class="explore">
        <ng-container *ngFor="let category of categories">
            <button mat-menu-item *ngIf="category?.subCategories?.length && category?.isComingSoon === false" [matMenuTriggerFor]="subCategoriesMenu"
                    (mouseenter)="mouseEnter(menuTrigger); hoveredCategory = category"
                    (click)="onCategoryClicked(category)"
                    (mouseleave)="mouseLeave(menuTrigger)">{{category.name}}</button>

            <button mat-menu-item *ngIf="!category?.subCategories?.length && category?.isComingSoon === false"
                    (mouseenter)="mouseEnter(menuTrigger); hoveredCategory = category"
                    (click)="onCategoryClicked(category)"
                    (mouseleave)="mouseLeave(menuTrigger)">{{category.name}}</button>
        </ng-container>
    </mat-menu>

    <mat-menu #subCategoriesMenu="matMenu" class="explore">
        <ng-container *ngFor="let subCategory of hoveredCategory?.subCategories">
            <button mat-menu-item *ngIf="subCategory?.topics?.length" [matMenuTriggerFor]="topic"
                    (mouseenter)="mouseEnter(menuTrigger); hoveredSubCategory = subCategory"
                    (click)="onSubCategoryClicked(subCategory)"
                    (mouseleave)="mouseLeave(menuTrigger)">{{subCategory.name}}</button>

            <button mat-menu-item *ngIf="!subCategory?.topics?.length"
                    (click)="onSubCategoryClicked(subCategory)"
                    (mouseenter)="mouseEnter(menuTrigger); hoveredSubCategory = subCategory"
                    (mouseleave)="mouseLeave(menuTrigger)">{{subCategory.name}}</button>
        </ng-container>
    </mat-menu>

    <mat-menu #topic="matMenu" class="explore">
        <button mat-menu-item *ngFor="let topic of hoveredSubCategory?.topics" (mouseenter)="mouseEnter(menuTrigger)"
                (mouseleave)="mouseLeave(menuTrigger)"
                (click)="onTopicClicked(topic)">{{topic.name}}</button>
    </mat-menu>
</ng-container>
