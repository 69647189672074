import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'app-small-dialog',
    template: `
        <div class="loader-dialog flex text-center flex-col items-center px-2 md:px-4 md:flex-row">
            <div class="mr-3 icon-cont">
                <div class="icon"></div>
            </div>
            <div class="text-3xl pt-4 md:pt-0">
                {{(data.message || 'LOADING') | transloco}}...
            </div>
        </div>
    `,
    styles: [`
        @import "~styles/variables/main";
        .loader-dialog {
            padding-top: 5px;
            border-radius: 8px;

            .icon-cont {
                width: 48px;
                height: 48px;
                padding: 4.6px;
                background-color: $purple-300;
                border-radius: 50%;

                .icon {
                    width: 39px;
                    height: 39px;
                    background: url("#{$path-to-icons}/rotate-refresh-loading.svg");
                }
            }
        }
    `]
})
export class SmallDialogComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public data: { message: string }
    ) {
    }

    ngOnInit(): void {
    }
}
