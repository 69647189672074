import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthService} from 'app/core/auth/auth.service';
import {AuthUtils} from 'app/core/auth/auth.utils';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SharedService} from '../../shared/services/shared.service';
import {Router} from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private _authService: AuthService,
                private _snackbar: MatSnackBar,
                private _sharedService: SharedService,
                private _router: Router,
                private _translocoService: TranslocoService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let newReq = req.clone();
        if (this._authService.accessToken && !AuthUtils.isTokenExpired(this._authService.accessToken.split(' ')[1])) {
            newReq = req.clone({
                headers: req.headers.set('Authorization', this._authService.accessToken)
            });
        }

        return next.handle(newReq).pipe(
            catchError((event: HttpErrorResponse) => {
                if (event instanceof HttpErrorResponse) {
                    switch (event.status) {
                        case 401:
                        // as per BE we expect only to receive 403 error code for any Api with authentication(Token), but I added this just in case,
                        // P.S: 401 should be for expired tokens to call the refreshToken Api, but they have different imp here.
                            return throwError(event);
                        case 403:
                            this.#removeTokensAndGoToLogin();
                            return throwError(event);
                        case 500:
                            this._snackbar.open(this._translocoService.translate('ERRORS.INTERNAL_SERVER_ERROR'), this._translocoService.translate('CLOSE'), {
                                panelClass: ['bg-black', 'text-white']
                            });
                            break;
                        case 0:
                            this._snackbar.open(this._translocoService.translate('ERRORS.CHECK_INTERNET_MSG'), this._translocoService.translate('CLOSE'), {
                                panelClass: ['bg-black', 'text-white']
                            });
                            break;
                        default:
                            return throwError(event.error?.message);
                    }
                }
                return throwError(event);
            }),
        );
    }

    #removeTokensAndGoToLogin(): void {
        this._authService.signOutIfTokenExist().subscribe();
        this._sharedService.removeCookie('accessToken');
        this._authService.refresh();
        this._router.navigate(['auth']);
    }
}
