import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HeaderComponent} from './components/header/header.component';
import {MatButtonModule} from '@angular/material/button';
import {FooterComponent} from './components/footer/footer.component';
import {RatingStarComponent} from './components/rating-star/rating-star.component';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {RouterModule} from '@angular/router';
import {SearchModule} from '../layout/common/search/search.module';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {UserMenuComponent} from './components/user-menu/user-menu.component';
import {MatDividerModule} from '@angular/material/divider';
import {TranslocoModule} from '@ngneat/transloco';
import {ExploreComponent} from './components/header/explore/explore.component';
import {StopPropagationDirective} from './directives/stop-propagation/stop-propagation.directive';
import {SmallMenuComponent} from './components/user-menu/small-menu/small-menu.component';
import {NotificationsMenuComponent} from './components/notifications-menu/notifications-menu.component';
import {MatBadgeModule} from '@angular/material/badge';
import {NotificationItemComponent} from './components/notifications-menu/notification-item/notification-item.component';
import {
    NotificationToastComponent
} from './components/notifications-menu/notification-toast/notification-toast.component';
import {InputRefDirective} from './directives/input-trim.directive';
import { SmallDialogComponent } from './components/small-dilaog/small-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatInputModule,
        RouterModule,
        SearchModule,
        MatDividerModule,
        TranslocoModule,
        MatBadgeModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HeaderComponent,
        FooterComponent,
        RatingStarComponent,
        StopPropagationDirective,
        InputRefDirective
    ],
    declarations: [
        HeaderComponent,
        FooterComponent,
        RatingStarComponent,
        NotFoundComponent,
        UserMenuComponent,
        ExploreComponent,
        StopPropagationDirective,
        SmallMenuComponent,
        NotificationsMenuComponent,
        NotificationItemComponent,
        NotificationToastComponent,
        InputRefDirective,
        SmallDialogComponent
    ]
})
export class SharedModule {
}
